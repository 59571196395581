import React, { useEffect, useState } from 'react';
import {
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useToast,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import isEmpty from 'lodash/isEmpty';
import useAxios from 'axios-hooks';
import InputController from 'components/Form/InputController';
import SelectController from 'components/Form/SelectController';
import { API_ROUTES } from 'utils/constant';

const defaultValues = {
  brand: '',
};

const CreateTokenModal = ({ isOpen, channelDetail, onClose, refetchData }) => {
  const cancelRef = React.useRef();
  const toast = useToast();
  const [{ loading: createLoading }, createIPSApi] = useAxios(
    {
      url: API_ROUTES.CreateTokenRemoteBrand,
      method: 'post',
    },
    { manual: true }
  );
  const schema = yup.object().shape({
    brand: yup.string().required('Vui lòng nhập brand'),
  });
  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });

  useEffect(() => () => reset(), []);

  const handleSuccess = () => {
    toast({
      title: 'Token has been added',
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
    refetchData?.();
    onClose?.();
  };

  const handleError = error => {
    toast({
      title: error?.response?.data?.errors?.errors?.[0]?.msg || error?.response?.data?.msg || 'Add token not successfully',
      status: 'error',
      duration: 9000,
      isClosable: true,
    });
  };

  const onSubmit = values => {
    toast.closeAll();

    createIPSApi({
      data: {
        name: values?.brand,
      },
    })
      .then(res => {
        handleSuccess();
      })
      .catch(error => {
        handleError(error);
      });
  };

  return (
    <>
      <AlertDialog
        motionPreset="slideInBottom"
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClose?.();
        }}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader textTransform="uppercase">Tạo token</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <form>
              <InputController
                styleBoxInput={{ flexShrink: '1', flexGrow: '1', flexBasis: '0' }}
                control={control}
                name="brand"
                label="Brand"
                isRequired
              />
            </form>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              ref={cancelRef}
              onClick={() => {
                onClose?.();
              }}
            >
              Cancel
            </Button>
            <Button colorScheme="blue" ml={3} isLoading={createLoading} onClick={handleSubmit(onSubmit)}>
              Add
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CreateTokenModal;
