import React from 'react';
import { Flex, Td, Text, Tr, useColorModeValue, useToast } from '@chakra-ui/react';
import { formatDate } from 'utils/helpers';
import moment from 'moment';
import { FaCopy } from 'react-icons/fa';
import { DeleteIcon } from '@chakra-ui/icons';
import { API_ROUTES } from 'utils/constant';
import useAxios from 'axios-hooks';

function Row({ smsDetail, isLast, refetch }) {
  const toast = useToast();
  const textColor = useColorModeValue('gray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  const [{ loading: exportLoading }, deleteRemoteApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.DeleteTokenRemoteBrand,
    },
    { manual: true }
  );

  const handleDeleteRecord = (brandId) => {
    if (window.confirm('Are you sure to remove this token')) {
      deleteRemoteApi({ data: { brandId } })
        .then(response => {
          toast({
            title: 'Xóa thành công',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          refetch?.()
        })
        .catch(error => {
          toast({
            title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Xóa không thành công',
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
        });
    }
  };

  return (
    <Tr>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" noOfLines={2}>
            {smsDetail?.brand || ''}
          </Text>
        </Flex>
      </Td>
      <Td borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex gap={2}>
          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={{ sm: '150px' }} display={'flex'} alignItems={'center'} gap={2}>
            <p>{smsDetail?.apiKey}</p>
          </Text>
          <FaCopy cursor={'pointer'} onClick={() => navigator.clipboard.writeText(smsDetail?.apiKey)} />
        </Flex>
      </Td>
      <Td minWidth={{ sm: '100px' }} borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <Text fontSize="md" color={textColor} fontWeight="bold" minWidth={{ sm: '100px' }}>
            {formatDate(smsDetail?.createdAt, 'DD-MM-YYYY HH:mm:ss')}
          </Text>
        </Flex>
      </Td>
      <Td minWidth={{ sm: '100px' }} borderColor={borderColor} borderBottom={isLast ? 'none' : null}>
        <Flex direction="column">
          <DeleteIcon color={'red.500'} cursor={'pointer'} onClick={() => handleDeleteRecord(smsDetail?._id)} />
        </Flex>
      </Td>
    </Tr>
  );
}

export default Row;
