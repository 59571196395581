import React from 'react';
import { HomeIcon, StatsIcon, PersonIcon, SettingsIcon } from 'components/Icons/Icons';
import { Groups, Roles } from 'utils/constant';
import Dashboard from 'views/Dashboard/Dashboard.js';
import SignIn from 'views/Pages/SignIn.js';
// import Email from 'views/Dashboard/Email/Email';
// import SignUp from "views/Pages/SignUp.js";
// import Campaign from 'views/Dashboard/Campaign/Campaign';
// import Customer from 'views/Dashboard/Customer/Customer';
// import CustomerGroup from 'views/Dashboard/CustomerGroup/CustomerGroup';
// import CampaignManagement from 'views/Dashboard/CampaignManagement/CampaignManagement';
import Setting from 'views/Dashboard/Setting/Setting';
import Template from 'views/Dashboard/Template/Template';
import Profile from 'views/Dashboard/Profile';
import SMSList from 'views/Dashboard/SMS';
import WhitelistIP from 'views/Dashboard/WhitelistIP';
import VoiceOtp from 'views/Dashboard/VoiceOtp';
import ZaloOA from 'views/Dashboard/ZaloOA';
import ZaloOADetail from 'views/Dashboard/ZaloOA/components/ZaloOADetail';
import { BsFillSendFill } from 'react-icons/bs';
import SendVoiceOtp from 'views/Dashboard/VoiceOtp/components/SendVoiceOtp';
import History8day from 'views/Dashboard/VoiceOtp/History8day';
import History888d from 'views/Dashboard/VoiceOtp/History888d';
import { FaKey } from 'react-icons/fa';
import TokenBrand from 'views/Dashboard/TokenBrand';

var dashRoutes = [
  // {
  //   path: '/dashboard',
  //   name: 'Dashboard',
  //   icon: <HomeIcon color="inherit" />,
  //   component: Dashboard,
  //   layout: '/admin',
  //   role: [Roles.ADMIN],
  // },
  {
    path: '/lich-su-cuoc-goi',
    name: 'Lịch sử cuộc gọi',
    icon: <StatsIcon color="inherit" />,
    component: VoiceOtp,
    layout: '/admin',
    role: [Roles.ADMIN],
  },
  {
    path: '/lich-su-cuoc-goi-8day',
    name: 'Lịch sử cuộc gọi 8day',
    icon: <StatsIcon color="inherit" />,
    component: History8day,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.MANAGER],
  },
  {
    path: '/lich-su-cuoc-goi-888b',
    name: 'Lịch sử cuộc gọi 888b',
    icon: <StatsIcon color="inherit" />,
    component: History888d,
    layout: '/admin',
    role: [Roles.ADMIN, Roles.MANAGER],
  },
  {
    path: '/lich-su-sms',
    name: 'Lịch sử sms',
    icon: <StatsIcon color="inherit" />,
    component: SMSList,
    layout: '/admin',
    role: [Roles.ADMIN],
    group: [Groups.GROUP_ADMIN],
  },
  {
    path: '/whitelist-ip',
    name: 'Whitelist IP',
    icon: <StatsIcon color="inherit" />,
    component: WhitelistIP,
    layout: '/admin',
    role: [Roles.ADMIN],
    group: [Groups.GROUP_ADMIN],
  },
  {
    path: '/zalo-official-account/:id',
    name: 'Quản lý OA',
    icon: <StatsIcon color="inherit" />,
    redirect: true,
    component: ZaloOADetail,
    layout: '/admin',
    role: [Roles.ADMIN],
    group: [Groups.GROUP_ADMIN],
  },
  {
    path: '/zalo-official-account',
    name: 'Quản lý OA',
    icon: <StatsIcon color="inherit" />,
    component: ZaloOA,
    layout: '/admin',
    role: [Roles.ADMIN],
    group: [Groups.GROUP_ADMIN],
  },
  {
    path: '/voice-otp',
    name: 'Send Voice OTP',
    icon: <BsFillSendFill color="inherit" />,
    component: SendVoiceOtp,
    layout: '/admin',
    role: [Roles.ADMIN],
    group: [Groups.GROUP_ADMIN],
  },
  {
    path: '/setting',
    name: 'Setting',
    icon: <SettingsIcon color="inherit" />,
    component: Setting,
    layout: '/admin',
    role: [Roles.ADMIN],
    group: [Groups.GROUP_ADMIN],
  },
  {
    path: '/template',
    name: 'Template',
    icon: <StatsIcon color="inherit" />,
    component: Template,
    layout: '/admin',
    role: [Roles.ADMIN],
    group: [Groups.GROUP_ADMIN],
  },
  {
    path: '/gen-token',
    name: 'Token brand',
    icon: <FaKey color="inherit" />,
    component: TokenBrand,
    layout: '/admin',
    role: [Roles.ADMIN],
    group: [Groups.GROUP_ADMIN],
  },
  {
    name: 'ACCOUNT PAGES',
    category: 'account',
    rtlName: 'صفحات',
    state: 'pageCollapse',
    views: [
      {
        path: '/profile',
        name: 'Profile',
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: '/admin',
        role: [Roles.ADMIN, Roles.USER, Roles.GUEST],
      },
      {
        path: '/signin',
        component: SignIn,
        layout: '/auth',
        group: [Groups.NOBODY],
      },
      // {
      //   path: "/signup",
      //   name: "Sign Up",
      //   rtlName: "لوحة القيادة",
      //   icon: <RocketIcon color='inherit' />,
      //   component: SignUp,
      //   layout: "/auth",
      // },
    ],
  },
];
export default dashRoutes;
