import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { ModalType, STATUS_CALLEE, STATUS_ZNS, initialFilter } from 'utils/constant';
import Pagination from 'components/Pagination/Pagination';
import { API_ROUTES } from 'utils/constant';
import Row from './components/Row';
import InputSearch from 'components/InputSearch/InputSearch';
import CreateTokenModal from './components/CreateTokenModal';

const TokenBrand = () => {
  const [filter, setFilter] = useState({ ...initialFilter });
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState('');
  const toast = useToast();

  const { isOpen: isOpenAddModal, onOpen: onOpenAddModal, onClose: onCloseAddModal } = useDisclosure();

  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.TokenRemoteBrand,
      params: filter,
    },
    {
      useCache: false,
    },
    {
      manual: true,
    }
  );
  // const [{ data: znsQuota, loading: loadingZns }, refetchZns] = useAxios(
  //   {
  //     url: API_ROUTES.ZnsQuota,
  //   },
  //   {
  //     useCache: false,
  //   },
  //   {
  //     manual: true,
  //   }
  // );
  // const [{ data: znsQuality, loading: loadingZnsQuality }, refetchZnsQuality] = useAxios(
  //   {
  //     url: API_ROUTES.ZnsQuality,
  //   },
  //   {
  //     useCache: false,
  //   },
  //   {
  //     manual: true,
  //   }
  // );
  // const [{ loading: exportLoading }, exportHistoryApi] = useAxios(
  //   {
  //     method: 'post',
  //     url: API_ROUTES.ExportHistory,
  //     params: filter,
  //     responseType: 'arraybuffer',
  //   },
  //   { manual: true }
  // );
  const onClearSearch = () => {
    setFilter(initialFilter);
    setSearchKeyword('');
  };

  const onChangeSearch = event => {
    event.persist();
    setSearchKeyword(event.target.value);
  };

  const onFilter = () => {
    setFilter({
      ...filter,
      pageIndex: 0,
      searchKeyword,
      // startDate: startDate ? moment(startDate).toISOString() : '',
      // endDate: endDate ? moment(endDate).endOf('days').toISOString() : '',
      // statusCode: status,
    });
  };

  // const onChangeDate = type => date => {
  //   if (type === 'startDate') {
  //     setStartDate(date);
  //   } else {
  //     setEndDate(date);
  //   }
  // };

  // const onExportHistory = () => {
  //   if (!startDate || !endDate) {
  //     toast({
  //       title: 'Vui lòng chọn thời gian bắt đầu và kết thúc !',
  //       status: 'error',
  //       duration: 9000,
  //       isClosable: true,
  //     });
  //     return;
  //   }
  //   if (moment(endDate).diff(moment(startDate), 'days') > 31) {
  //     toast({
  //       title: 'Vui lòng chọn trong khoảng thời gian 31 ngày',
  //       status: 'error',
  //       duration: 9000,
  //       isClosable: true,
  //     });
  //     return;
  //   }
  //   exportHistoryApi({
  //     params: {
  //       searchKeyword,
  //       startDate: startDate ? moment(startDate).toISOString() : '',
  //       endDate: endDate ? moment(endDate).endOf('days').toISOString() : '',
  //       status,
  //     },
  //   })
  //     .then(response => {
  //       downloadFile(response?.data, 'call-out-history');
  //       toast({
  //         title: 'Export thành công',
  //         status: 'success',
  //         duration: 9000,
  //         isClosable: true,
  //       });
  //     })
  //     .catch(error => {
  //       console.log(error);
  //       toast({
  //         title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Export không thành công',
  //         status: 'error',
  //         duration: 9000,
  //         isClosable: true,
  //       });
  //     });
  // };
  const onResetFilter = () => {
    setFilter(initialFilter);
    setStartDate('');
    setEndDate('');
    setStatus('');
    setSearchKeyword('');
  };
  return (
    <>
      <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Token brand
            </Text>
            <Button variant="primary" maxH="30px" my="10px" onClick={onOpenAddModal}>
              Tạo token
            </Button>
          </CardHeader>
          <CardBody>
            <Stack>
              <Flex>
                <InputSearch value={searchKeyword} onChange={onChangeSearch} onClearSearch={onClearSearch} />
                {/* <FormControl display="flex" alignItems="center" maxW="300px" mr="12px">
                  <FormLabel m="0" pr="10px">
                    Trạng thái
                  </FormLabel>
                  <Select
                    onChange={e => {
                      setStatus(e.target.value);
                    }}
                    value={status}
                    w={'auto'}
                  >
                    <option value={''}></option>
                    {Object.keys(STATUS_ZNS).map(st => (
                      <option key={st} value={st}>
                        {get(STATUS_ZNS, st)}
                      </option>
                    ))}
                  </Select>
                </FormControl> */}
              <Flex>
                {/* <FormControl display="flex" alignItems="center" maxW="300px" mr="12px">
                  <FormLabel m="0" pr="10px">
                    Start Date:
                  </FormLabel>
                  <DatePicker selectedDate={startDate} onChange={date => onChangeDate('startDate')(date)} />
                </FormControl>
                <FormControl display="flex" alignItems="center" maxW="300px" mr="12px">
                  <FormLabel m="0" pr="10px">
                    End Date:
                  </FormLabel>
                  <DatePicker selectedDate={endDate} minDate={startDate} onChange={date => onChangeDate('endDate')(date)} />
                </FormControl> */}
                <Button variant="primary" maxH="30px" m="10px" onClick={onFilter}>
                  Lọc
                </Button>
                <Button variant="primary" maxH="30px" m="10px" onClick={onResetFilter}>
                  Cài lại
                </Button>
                {/* <Button variant="primary" maxH="30px" m="10px" onClick={onExportHistory}>
                  Export
                </Button> */}
              </Flex>
              </Flex>
            </Stack>
            <Stack overflow="auto">
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th borderColor={borderColor} color="gray.400">
                      Brand
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Api key
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Tạo lúc
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.data?.map((row, index, arr) => (
                    <Row key={row._id} smsDetail={row} isLast={index === arr.length - 1 ? true : false} refetch={refetch}/>
                  ))}
                  {isEmpty(data?.data) && !loading && (
                    <Tr>
                      <Td colSpan="9">
                        <Box textAlign="center" height="200px" pt="24px">
                          No data
                        </Box>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Stack>

            <Flex justifyContent={'flex-end'}>
              {!isEmpty(data?.data) && (
                <Pagination
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              )}
            </Flex>
          </CardBody>
        </Card>
      </Box>
      {isOpenAddModal && <CreateTokenModal isOpen={isOpenAddModal} onClose={onCloseAddModal} refetchData={refetch} />}
    </>
  );
};

export default TokenBrand;
