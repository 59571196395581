import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Spinner,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import useAxios from 'axios-hooks';
import isEmpty from 'lodash/isEmpty';
import Card from 'components/Card/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { Groups, PARTNER_VOICE, STATUS_CALLEE, VENDOR, initialFilter } from 'utils/constant';
import Pagination from 'components/Pagination/Pagination';
import { API_ROUTES } from 'utils/constant';
import Row from './components/Row';
import InputSearch from 'components/InputSearch/InputSearch';
import DatePicker from 'components/DatePicker/DatePicker';
import { convertSecondsToHours, downloadFile, formatDate } from 'utils/helpers';
import moment from 'moment';
import { get } from 'lodash';
import AuthorizationGroupWrapper from 'components/AuthorizationGroupWrapper/AuthorizationGroupWrapper';

const History8day = () => {
  const [filter, setFilter] = useState({ ...initialFilter });
  const textColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [searchKeyword, setSearchKeyword] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [callstatus, setCallStatus] = useState('');
  const [vendor, setVendor] = useState('');
  const [partner, setPartner] = useState('');
  const toast = useToast();
  const [{ data, loading }, refetch] = useAxios(
    {
      url: API_ROUTES.VoiceOtp,
      params: {
        ...filter,
        brand: '8day',
      },
    },
    {
      useCache: false,
    }
  );
  const [{ data: total, loading: loadingTotalBillSec }] = useAxios(
    {
      url: API_ROUTES.totalBillSect,
      params: {
        startDate: filter?.startDate,
        endDate: filter?.endDate,
        brand: '8day',
      },
    },
    {
      useCache: false,
    }
  );
  const [{ loading: exportLoading }, exportHistoryApi] = useAxios(
    {
      method: 'post',
      url: API_ROUTES.ExportHistory,
      params: {
        ...filter,
        brand: '8day',
      },
      responseType: 'arraybuffer',
    },
    { manual: true }
  );

  useEffect(() => {
    const fetchApi = setInterval(() => {
      refetch();
    }, 60000);

    return () => clearInterval(fetchApi);
  }, []);

  const onClearSearch = () => {
    setFilter(initialFilter);
    setSearchKeyword('');
  };

  const onChangeSearch = event => {
    event.persist();
    setSearchKeyword(event.target.value);
  };

  const onFilter = () => {
    setFilter({
      ...filter,
      pageIndex: 0,
      searchKeyword,
      startDate: startDate ? moment(startDate).toISOString() : '',
      endDate: endDate ? moment(endDate).endOf('days').toISOString() : '',
      callstatus,
      vendor,
      partner,
    });
  };

  const onChangeDate = type => date => {
    if (type === 'startDate') {
      setStartDate(date);
      setEndDate(null);
    } else {
      setEndDate(date);
    }
  };

  const onExportHistory = () => {
    if (!startDate || !endDate) {
      toast({
        title: 'Vui lòng chọn thời gian bắt đầu và kết thúc !',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    if (moment(endDate).diff(moment(startDate), 'days') > 31) {
      toast({
        title: 'Vui lòng chọn trong khoảng thời gian 31 ngày',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    exportHistoryApi({
      params: {
        searchKeyword,
        startDate: startDate ? moment(startDate).toISOString() : '',
        endDate: endDate ? moment(endDate).endOf('days').toISOString() : '',
        callstatus: callstatus == '' ? 'CALLING' : callstatus,
        vendor,
        partner,
      },
    })
      .then(response => {
        downloadFile(response?.data, 'call-out-history');
        toast({
          title: 'Export thành công',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(error => {
        toast({
          title: error?.response?.data?.errors?.[0]?.msg || error?.response?.data?.msg || 'Export không thành công',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  };
  const onResetFilter = () => {
    setFilter(initialFilter);
    setStartDate('');
    setEndDate('');
    setCallStatus('');
    setSearchKeyword('');
  };

  const maxEndDate = startDate ? new Date(startDate.getTime() + 60 * 24 * 60 * 60 * 1000) : null;
  return (
    <>
      <Box bg="white" mt={{ base: '120px', md: '75px' }} p="10">
        <Card overflowX={{ sm: 'scroll', xl: 'hidden' }} pb="0px">
          <CardHeader p="6px 0px 22px 0px">
            <Text fontSize="xl" color={textColor} fontWeight="bold">
              Lịch sử cuộc gọi 8day
            </Text>
          </CardHeader>
          <CardBody>
            <Stack>
              {filter?.startDate && filter?.endDate && (
                <Flex flexDirection={'column'}>
                  <Text fontSize="md" color={textColor} fontWeight="bold">
                    <Text mr={3} display={'inline-block'}>
                      Tổng số phút gọi:
                    </Text>
                    {loadingTotalBillSec ? <Spinner ml={4} size="xs" /> : convertSecondsToHours(total?.data?.totalBillsec) || 0}
                  </Text>
                  <Text fontSize="md" color={textColor} fontWeight="bold">
                    Tổng số cuộc gọi: {total?.data?.totalVoice || 0}
                  </Text>
                </Flex>
              )}
              <Flex>
                <InputSearch value={searchKeyword} onChange={onChangeSearch} onClearSearch={onClearSearch} />
                <FormControl display="flex" alignItems="center" maxW="300px" mr="12px">
                  <FormLabel m="0" pr="10px">
                    Trạng thái
                  </FormLabel>
                  <Select
                    onChange={e => {
                      setCallStatus(e.target.value);
                    }}
                    value={callstatus}
                    w={'auto'}
                  >
                    <option value={''}></option>
                    {Object.keys(STATUS_CALLEE).map(st => (
                      <option key={st} value={get(STATUS_CALLEE, st)}>
                        {get(STATUS_CALLEE, st)}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <AuthorizationGroupWrapper allowedGroups={[Groups.GROUP_ADMIN]}>
                  <FormControl display="flex" alignItems="center" maxW="300px" mr="12px">
                    <FormLabel m="0" pr="10px">
                      Vendor
                    </FormLabel>
                    <Select
                      onChange={e => {
                        setVendor(e.target.value);
                      }}
                      value={vendor}
                      w={'auto'}
                    >
                      <option value={''}></option>
                      {Object.keys(VENDOR).map(st => (
                        <option key={st} value={get(VENDOR, st)}>
                          {get(VENDOR, st)}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </AuthorizationGroupWrapper>
                <AuthorizationGroupWrapper allowedGroups={[Groups.GROUP_ADMIN]}>
                  <FormControl display="flex" alignItems="center" maxW="300px" mr="12px">
                    <FormLabel m="0" pr="10px">
                      Partner
                    </FormLabel>
                    <Select
                      onChange={e => {
                        setPartner(e.target.value);
                      }}
                      value={partner}
                      w={'auto'}
                    >
                      <option value={''}></option>
                      {Object.keys(PARTNER_VOICE).map(st => (
                        <option key={st} value={get(PARTNER_VOICE, st)}>
                          {get(PARTNER_VOICE, st)}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </AuthorizationGroupWrapper>
              </Flex>
              <Flex>
                <FormControl display="flex" alignItems="center" maxW="300px" mr="12px">
                  <FormLabel m="0" pr="10px">
                    Start Date:
                  </FormLabel>
                  <DatePicker selectedDate={startDate} onChange={date => onChangeDate('startDate')(date)} />
                </FormControl>
                <FormControl display="flex" alignItems="center" maxW="300px" mr="12px">
                  <FormLabel m="0" pr="10px">
                    End Date:
                  </FormLabel>
                  <DatePicker
                    selectedDate={endDate}
                    minDate={startDate}
                    maxDate={maxEndDate}
                    onChange={date => onChangeDate('endDate')(date)}
                  />
                </FormControl>
                <Button variant="primary" maxH="30px" m="10px" onClick={onFilter}>
                  Lọc
                </Button>
                <Button variant="primary" maxH="30px" m="10px" onClick={onResetFilter}>
                  Cài lại
                </Button>
                <Button variant="primary" maxH="30px" m="10px" onClick={onExportHistory}>
                  Export
                </Button>
              </Flex>
            </Stack>
            <Stack overflow="auto">
              <Table variant="simple" color={textColor}>
                <Thead>
                  <Tr my=".8rem" pl="0px" color="gray.400">
                    <Th pl="0px" borderColor={borderColor} color="gray.400">
                      Call ID
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Phone number
                    </Th>
                    {/* <Th borderColor={borderColor} color="gray.400">
                      OTP Code
                    </Th> */}
                    <Th pl="0px" borderColor={borderColor} color="gray.400">
                      Caller Number / SMS
                    </Th>
                    <AuthorizationGroupWrapper allowedGroups={[Groups.GROUP_ADMIN]}>
                      <Th pl="0px" borderColor={borderColor} color="gray.400">
                        Vendor
                      </Th>
                    </AuthorizationGroupWrapper>
                    <Th borderColor={borderColor} color="gray.400">
                      Trạng thái chờ
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Trạng thái cuộc gọi/ Trạng thái tin nhắn
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Thời gian gọi
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Tạo lúc
                    </Th>
                    <Th borderColor={borderColor} color="gray.400">
                      Phản hồi lúc
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.data?.map((row, index, arr) => (
                    <Row key={row.id} smsDetail={row} isLast={index === arr.length - 1 ? true : false} />
                  ))}
                  {isEmpty(data?.data) && !loading && (
                    <Tr>
                      <Td colSpan="9">
                        <Box textAlign="center" height="200px" pt="24px">
                          No data
                        </Box>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Stack>

            <Flex justifyContent={'flex-end'}>
              {!isEmpty(data?.data) && (
                <Pagination
                  page={data?.pagination?.page}
                  pageLength={data?.pagination?.pageSize}
                  totalRecords={data?.pagination?.count}
                  onPageChange={(page, pageLength) => {
                    setFilter({
                      ...filter,
                      pageSize: pageLength,
                      pageIndex: page - 1,
                    });
                  }}
                />
              )}
            </Flex>
          </CardBody>
        </Card>
      </Box>
    </>
  );
};

export default History8day;
